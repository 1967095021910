import { template as template_6cb0cfd38f324bdfa39d895391ffb1ec } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_6cb0cfd38f324bdfa39d895391ffb1ec(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
