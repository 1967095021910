import { template as template_76d9742bd1e1436b84c276468cf758cb } from "@ember/template-compiler";
const FKLabel = template_76d9742bd1e1436b84c276468cf758cb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
